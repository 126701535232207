import { Fragment, useEffect, useState } from 'react';
import styles from '../../../styles/Footer.module.scss';
import { KNOWLEDGE_CENTER } from '@/config/index';
import { getFooterData } from '@/redux/actions/main';
import { connect } from 'react-redux';
import style from './styles';
import classes from './mobileFooter.module.scss';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Link from 'next/link';
import ClassNames from 'classnames';
// import { ExpandMore, ExpandLess } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { IconButton } from '@mui/material';

const mobile_footer_links = {
  'About Us': '/about-us',
  Career: 'https://careers.homebazaar.com',
  'Our Culture': '/our-culture',
  Knowledge: KNOWLEDGE_CENTER,
  'Privacy Policy': '/privacy-policy',
  Sitemap: '/sitemap',
  News: '/news/',
  'Contact Us': '/contactus',
};

const MobileFooter = props => {
  const { module_type, data, pageType } = props;
  const [moduletype] = useState(module_type ? module_type : 'buy');
  const { global_selected_city } = props.headersApiData;
  props.apiData.isEmpty && data ? data : props.apiData;
  const [, setCurrentCity] = useState(props?.city?.id);
  const [, setCityName] = useState(props?.city?.name);

  useEffect(() => {
    let mounted = false;
    setCurrentCity(global_selected_city?.id);
    if (!mounted) {
      props.getFooterData({
        // city_id: global_selected_city?.id || 1,
        city_id: props?.city?.id || 1,
        module_type: moduletype,
      });
      setCityName(global_selected_city?.name || 'mumbai');
    }
    // }
    return () => {
      mounted = true;
    };
  }, [props?.city?.name]);

  const Accordion = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleClick = index => {
      setOpenIndex(openIndex === index ? null : index);
    };

    const sections = [
      /* accordion section 1 */

      {
        title: (
          <Grid>
            <h4>Popular Localities in India</h4>
          </Grid>
        ),

        content: (
          <Grid container wrap="nowrap">
            <Box sx={style.wrapper} spacing={1}>
              <Grid
                sx={style.item}
                size={{
                  xs: 4,
                  sm: 4,
                  md: 4,
                }}>
                <Box sx={style.footerSubTitle}>
                  <h5>Best Localities in Mumbai</h5>
                  <div className={classes.footerLinks1}>
                    <ul>
                      <li>
                        <a target="_blank" href="/properties-in-panvel-mumbai">
                          Property in Panvel
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="/properties-in-powai-mumbai">
                          Property in Powai
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-kharghar-mumbai">
                          Property in Kharghar
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-borivali-mumbai">
                          Property in Borivali
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="/properties-in-mulund-mumbai">
                          Property in Mulund
                        </a>
                      </li>
                    </ul>
                  </div>
                </Box>
              </Grid>
              <Grid
                sx={style.item}
                size={{
                  xs: 4,
                  sm: 4,
                  md: 4,
                }}>
                <Box sx={style.footerSubTitle}>
                  <h5>Best Localities in Pune</h5>
                  <div className={classes.footerLinks1}>
                    <ul>
                      <li>
                        <a target="_blank" href="/properties-in-hinjewadi-pune">
                          Property in Hinjewadi
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="/properties-in-wakad-pune">
                          Property in Wakad
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="/properties-in-wagholi-pune">
                          Property in Wagholi
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="/properties-in-baner-pune">
                          Property in Baner
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-viman-nagar-pune">
                          Property in Viman Nagar
                        </a>
                      </li>
                    </ul>
                  </div>
                </Box>
              </Grid>
              <Grid
                sx={style.item}
                size={{
                  xs: 4,
                  sm: 4,
                  md: 4,
                }}>
                <Box sx={style.footerSubTitle}>
                  <h5>Best Localities in Banglore</h5>
                  <div className={classes.footerLinks1}>
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-yelahanka-bangalore">
                          Property in Yelahanka
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-whitefield-bangalore">
                          Property in Whitefield
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-electronic-city-bangalore">
                          Property in Electronic City
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-devanahalli-bangalore">
                          Property in Devanahalli
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-varthur-bangalore">
                          Property in Varthur
                        </a>
                      </li>
                    </ul>
                  </div>
                </Box>
              </Grid>
              <Grid
                sx={style.item}
                size={{
                  xs: 4,
                  sm: 4,
                  md: 4,
                }}>
                <Box sx={style.footerSubTitle}>
                  <h5>Best Localities in Chennai</h5>
                  <div className={classes.footerLinks1}>
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-egattur-chennai">
                          Property in Egattur
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-nungambakkam-chennai">
                          Property in Nungambakkam
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="/properties-in-padur-chennai">
                          Property in Padur
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-anna-nagar-chennai">
                          Property in Anna Nagar
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="/properties-in-adyar-chennai">
                          Property in Adyar
                        </a>
                      </li>
                    </ul>
                  </div>
                </Box>
              </Grid>
              <Grid
                sx={style.item}
                size={{
                  xs: 4,
                  sm: 4,
                  md: 4,
                }}>
                <Box sx={style.footerSubTitle}>
                  <h5>Best Localities in Noida</h5>
                  <div className={classes.footerLinks1}>
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-sector-137-noida">
                          Property in Sector 137
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-sector-150-noida">
                          Property in Sector 150
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-sector-128-noida">
                          Property in Sector 128
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-sector-74-noida">
                          Property in Sector 74
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-sector-76-noida">
                          Property in Sector 76
                        </a>
                      </li>
                    </ul>
                  </div>
                </Box>
              </Grid>
              <Grid
                sx={style.item}
                size={{
                  xs: 4,
                  sm: 4,
                  md: 4,
                }}>
                <Box sx={style.footerSubTitle}>
                  <h5>Best Localities in Hyderabad</h5>
                  <div className={classes.footerLinks1}>
                    <ul>
                      <li>
                        <a
                          href="/properties-in-kokapet-hyderabad"
                          target="_blank">
                          Property in Kokapet
                        </a>
                      </li>
                      <li>
                        <a
                          href="/properties-in-hitec-city-hyderabad"
                          target="_blank">
                          Property in HITEC City
                        </a>
                      </li>
                      <li>
                        <a
                          href="/properties-in-kondapur-hyderabad"
                          target="_blank">
                          Property in Kondapur
                        </a>
                      </li>

                      <li>
                        <a
                          href="/properties-in-nanakaramguda-hyderabad"
                          target="_blank">
                          Property in Nanakaramguda
                        </a>
                      </li>
                      <li>
                        <a
                          href="/properties-in-choutuppal-hyderabad"
                          target="_blank">
                          Property in Choutuppal
                        </a>
                      </li>
                    </ul>
                  </div>
                </Box>
              </Grid>
            </Box>
          </Grid>
        ),
      },

      /* accordion section 2 */
      {
        title: (
          <Grid>
            <h4>Properties in India</h4>
          </Grid>
        ),
        content: (
          <Grid container wrap="nowrap">
            <Box sx={style.wrapper}>
              <Grid
                sx={style.item}
                size={{
                  xs: 5,
                  sm: 5,
                  md: 5,
                }}>
                <Box sx={style.footerSubTitle}>
                  <h5>Properties in Mumbai</h5>
                  <div className={classes.footerLinks1}>
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-navi-mumbai-mumbai">
                          Property in Navi Mumbai
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="/properties-in-thane-mumbai">
                          Property in Thane
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-central-mumbai-mumbai">
                          Property in Central Mumbai
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-western-mumbai-mumbai">
                          Property in Western Mumbai
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-south-mumbai-mumbai">
                          Property in South Mumbai
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-kalyan-dombivali-mumbai">
                          Property in Kalyan Dombivali
                        </a>
                      </li>
                    </ul>
                  </div>
                </Box>
              </Grid>
              <Grid
                sx={style.item}
                size={{
                  xs: 5,
                  sm: 5,
                  md: 5,
                }}>
                <Box sx={style.footerSubTitle}>
                  <h5>Properties in Pune</h5>
                  <div className={classes.footerLinks1}>
                    <ul>
                      <li>
                        <a target="_blank" href="/properties-in-pune-east-pune">
                          Property in Pune East
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="/properties-in-pune-west-pune">
                          Property in Pune West
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-pune-south-pune">
                          Property in Pune South
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-pune-north-pune">
                          Property in Pune North
                        </a>
                      </li>
                    </ul>
                  </div>
                </Box>
              </Grid>
              <Grid
                sx={style.item}
                size={{
                  xs: 5,
                  sm: 5,
                  md: 5,
                }}>
                <Box sx={style.footerSubTitle}>
                  <h5>Properties in Bangalore</h5>
                  <div className={classes.footerLinks1}>
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-south-bangalore-bangalore">
                          Property in South Bangalore
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-north-bangalore-bangalore">
                          Property in North Bangalore
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-east-bangalore-bangalore">
                          Property in East Bangalore
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-central-bangalore-bangalore">
                          Property in Central Bangalore
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-west-bangalore-bangalore">
                          Property in West Bangalore
                        </a>
                      </li>
                    </ul>
                  </div>
                </Box>
              </Grid>
              <Grid
                sx={style.item}
                size={{
                  xs: 5,
                  sm: 5,
                  md: 5,
                }}>
                <Box sx={style.footerSubTitle}>
                  <h5>Properties in Chennai</h5>
                  <div className={classes.footerLinks1}>
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-south-chennai-chennai">
                          Property in South Chennai
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-central-chennai-chennai">
                          Property in Central Chennai
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-west-chennai-chennai">
                          Property in West Chennai
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-north-chennai-chennai">
                          Property in North Chennai
                        </a>
                      </li>
                    </ul>
                  </div>
                </Box>
              </Grid>
              <Grid
                sx={style.item}
                size={{
                  xs: 5,
                  sm: 5,
                  md: 5,
                }}>
                <Box sx={style.footerSubTitle}>
                  <h5>Properties in Noida</h5>
                  <div className={classes.footerLinks1}>
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-noida-city-noida">
                          Property in Noida City
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-greater-noida-noida">
                          Property in Greater Noida
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="/properties-in-greater-noida-west-noida">
                          Property in Greater Noida West
                        </a>
                      </li>
                    </ul>
                  </div>
                </Box>
              </Grid>

              <Grid
                sx={style.item}
                size={{
                  xs: 5,
                  sm: 5,
                  md: 5,
                }}>
                <Box sx={style.footerSubTitle}>
                  <h5>Properties in Hyderabad</h5>
                  <div className={classes.footerLinks1}>
                    <ul>
                      <li>
                        <a
                          href="/properties-in-north-hyderabad-hyderabad"
                          target="_blank">
                          Property in North Hyderabad
                        </a>
                      </li>
                      <li>
                        <a
                          href="/properties-in-south-hyderabad-hyderabad"
                          target="_blank">
                          Property in South Hyderabad
                        </a>
                      </li>
                      <li>
                        <a
                          href="/properties-in-west-hyderabad-hyderabad"
                          target="_blank">
                          Property in West Hyderabad
                        </a>
                      </li>
                      <li>
                        <a
                          href="/properties-in-east-hyderabad-hyderabad"
                          target="_blank">
                          Property in East Hyderabad
                        </a>
                      </li>
                    </ul>
                  </div>
                </Box>
              </Grid>
            </Box>
          </Grid>
        ),
      },

      /* accordion section 3 */
      {
        title: (
          <Grid>
            <h4>Commercial Projects in India</h4>
          </Grid>
        ),
        content: (
          <Box sx={style.footerSubTitle}>
            <div className={classes.footerLinks1}>
              <ul>
                <li>
                  <a target="_blank" href="/commercial-properties-in-mumbai">
                    Commercial Property in Mumbai
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/commercial-properties-in-pune">
                    Commercial Property in Pune
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/commercial-properties-in-chennai">
                    Commercial Property in Chennai
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/commercial-properties-in-bangalore">
                    Commercial Property in Bangalore
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/commercial-properties-in-noida">
                    Commercial Property in Noida
                  </a>
                </li>
                <li>
                  <a href="/commercial-properties-in-hyderabad" target="_blank">
                    Commercial property in Hyderabad
                  </a>
                </li>
                <li>
                  <a href="/commercial-properties-in-gurgaon" target="_blank">
                    Commercial property in Gurgaon
                  </a>
                </li>
                <li>
                  <a href="/commercial-properties-in-lucknow" target="_blank">
                    Commercial property in Lucknow
                  </a>
                </li>
              </ul>
            </div>
          </Box>
        ),
      },

      /* accordion section 4 */
      {
        title: (
          <Grid>
            <h4>Real Estate in India</h4>
          </Grid>
        ),
        content: (
          <Box sx={style.footerSubTitle}>
            <div className={classes.footerLinks1}>
              <ul>
                <li>
                  <a target="_blank" href="/mumbai">
                    Mumbai Real Estate
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/pune">
                    Pune Real Estate
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/bangalore">
                    Bangalore Real Estate
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/chennai">
                    Chennai Real Estate
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/noida">
                    Noida Real Estate
                  </a>
                </li>
                <li>
                  <a href="/hyderabad" target="_blank">
                    Hyderabad Real Estate
                  </a>
                </li>
                <li>
                  <a href="/gurgaon" target="_blank">
                    Gurgaon Real Estate
                  </a>
                </li>
                <li>
                  <a href="/gurgaon" target="_blank">
                    Lucknow Real Estate
                  </a>
                </li>
              </ul>
            </div>
          </Box>
        ),
      },

      /* accordion section 5 */
      {
        title: (
          <Grid>
            <h4>New Projects in India</h4>
          </Grid>
        ),
        content: (
          <Box sx={style.footerSubTitle}>
            <div className={classes.footerLinks1}>
              <ul>
                <li>
                  <a
                    target="_blank"
                    href="/under-construction-projects-in-mumbai">
                    New Projects in Mumbai
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="/under-construction-projects-in-pune">
                    New Projects in Pune
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="/under-construction-projects-in-bangalore">
                    New Projects in Bangalore
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="/under-construction-projects-in-chennai">
                    New Projects in Chennai
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="/under-construction-projects-in-noida">
                    New Projects in Noida
                  </a>
                </li>
                <li>
                  <a
                    href="/under-construction-projects-in-hyderabad"
                    target="_blank">
                    New Projects in Hyderabad
                  </a>
                </li>
                <li>
                  <a
                    href="/under-construction-projects-in-gurgaon"
                    target="_blank">
                    New Projects in Gurgaon
                  </a>
                </li>
                <li>
                  <a
                    href="/under-construction-projects-in-lucknow"
                    target="_blank">
                    New Projects in Lucknow
                  </a>
                </li>
              </ul>
            </div>
          </Box>
        ),
      },

      /* accordion section 6 */
      {
        title: (
          <Grid>
            <h4>Top Cities In India</h4>{' '}
          </Grid>
        ),
        content: (
          <Box sx={style.footerSubTitle}>
            <div className={classes.footerLinks1}>
              <ul>
                <li>
                  <a target="_blank" href="/properties-in-mumbai">
                    Property in Mumbai
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/properties-in-pune">
                    Property in Pune
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/properties-in-bangalore">
                    Property in Bangalore
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/properties-in-chennai">
                    Property in Chennai
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/properties-in-noida">
                    Property in Noida
                  </a>
                </li>
                <li>
                  <a href="/properties-in-hyderabad" target="_blank">
                    Property in Hyderabad
                  </a>
                </li>
                <li>
                  <a href="/properties-in-gurgaon" target="_blank">
                    Property in Gurgaon
                  </a>
                </li>
                <li>
                  <a href="/properties-in-lucknow" target="_blank">
                    Property in Lucknow
                  </a>
                </li>
              </ul>
            </div>
          </Box>
        ),
      },
    ];

    return (
      <div className={classes.accordion} style={{ paddingTop: '10px' }}>
        {sections.map((section, index) => (
          <div key={index} className={classes.accordionItem}>
            <div
              className={classes.accordionHeader}
              onClick={() => handleClick(index)}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}>
              {section.title}
              <IconButton
                aria-label={openIndex === index ? 'collapse' : 'expand'}
                style={{ marginLeft: 'auto', background: 'transparent' }}>
                {openIndex === index ? (
                  <ArrowDropDownIcon />
                ) : (
                  <ArrowRightIcon />
                )}
              </IconButton>
            </div>
            {/* <Divider sx={style.horizontalLine} /> */}
            <div
              className={classes.accordionContent}
              style={{ display: openIndex === index ? 'block' : 'none' }}>
              <div>{section.content}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const links = Object.keys(mobile_footer_links).map((item, index) => {
    return (
      <Grid
        key={index}
        style={{
          height: '32px',
          marginBottom: '8px',
          paddingLeft: '1px',
        }}
        size={{
          xs: 4,
          sm: 4,
          md: 4,
        }}>
        <ul className={classes.footLinks}>
          <li style={{ color: '#4FACF0' }}>
            <a
              href={
                mobile_footer_links[item] !== undefined
                  ? mobile_footer_links[item]
                  : ''
              }
              rel="noreferrer"
              target="_blank"
              // //prefetch={false}
              style={{
                color: '#FFF',
                fontSize: '13px',
                fontStyle: 'Open Sans, SemiBold',
                textAlign: 'left',
              }}>
              <span style={{ color: 'white', textWrap: 'nowrap' }}>{item}</span>
            </a>
          </li>
        </ul>
      </Grid>
    );
  });
  // @TO-DO removed all <a>tags by <a/>
  return (
    <Fragment>
      <section className={classes.footerContainer1}>
        <Accordion />
      </section>
      <section
        className={classes.footerContainer}
        style={{ border: '1px solid #ddd', marginTop: 'none !Important' }}>
        <Grid
          sx={style.contentWidth}
          size={{
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          }}>
          <div>
            <span style={{ fontWeight: '400' }}>We at </span>{' '}
            <span className={`${styles.spblack}`}>Home</span>
            <span className={`${styles.spblack}`}>Bazaar</span>
            <span className={`${styles.spblack}`}>.com</span>{' '}
            <span style={{ fontWeight: '400' }}>
              assist home buyers in negotiating with developers, &nbsp; act on
              behalf of our customers , &nbsp; and ensure that you receive the
              best deal in the market. We are also recognized as the
              &quot;Leading Property Advisors in India&quot; for several
              consecutive years.
              {/* is Known for Having &quot;Bulk Buying Power&quot; Which Helps
              &quot;Negotiate with Developers on Behalf of Customers&quot;.{' '} */}
              <br />
              {/* That&apos;s how{' '} */}
            </span>{' '}
            {/* <span className={`${styles.spanblue}`}>Home</span>
            <span className={`${styles.spanorange}`}>Bazaar</span>
            <span className={`${styles.spanblue}`}>.com</span>{' '}
            <span style={{ fontWeight: '400' }}>
              ensures to give you the lowest rate in the market and also awarded
              as the &quot;Leading Property Advisor&quot; for 2017-18.{' '}
            </span> */}
            <div className="container-fluid">
              <div
                style={{
                  ulStyle: 'none',
                  paddingLeft: 0,
                  paddingTop: '15px',
                  paddingBottom: '15px',
                }}>
                <Grid container sx={{ paddingTop: '20px' }}>
                  <Grid style={{ paddingRight: '13px' }}>
                    <Grid display="flex">
                      <Link
                        href="https://www.facebook.com/HomeBazaarcom/"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="facebook">
                        <div
                          className={ClassNames(
                            styles.socialIcons,
                            styles.facebook
                          )}></div>
                      </Link>
                      <Link
                        href="https://www.instagram.com/HomeBazaarcom/"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="instagram">
                        <div
                          className={ClassNames(
                            styles.socialIcons,
                            styles.instagram
                          )}></div>
                      </Link>
                      <Link
                        href="https://www.linkedin.com/company/HomeBazaarcom"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="linkdin">
                        <div
                          className={ClassNames(
                            styles.socialIcons,
                            styles.linkdin
                          )}></div>
                      </Link>
                      <Link
                        href="https://twitter.com/HomeBazaarcom"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="twitter">
                        <div
                          className={ClassNames(
                            styles.socialIcons,
                            styles.twitter
                          )}></div>
                      </Link>
                      <Link
                        href="https://www.youtube.com/HomeBazaarcom"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="youTube">
                        <div
                          className={ClassNames(
                            styles.socialIcons,
                            styles.youTube
                          )}></div>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
      </section>
      {module_type == 'rent' && pageType == 'PDP_Page' ? (
        <div className={classes.footerLinksContainer}>
          <Grid
            dirrection="column"
            container
            spacing={1}
            justifyContent={'start'}
            className={classes.footerLinks}>
            {links}
          </Grid>
        </div>
      ) : (
        <section
          style={{
            position: 'relative',
            padding: '0px',
            width: '100%',
            top: '0',
            bottom: '0',
            display: 'flex',
          }}>
          <div className={classes.footerLinksContainer}>
            <Grid
              dirrection="column"
              container
              spacing={1}
              justifyContent={'start'}
              className={classes.footerLinks}>
              {links}
            </Grid>
          </div>
        </section>
      )}
      {/* <Variant2 /> */}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  apiData: state.footerReducer,
  headersApiData: state.headerReducer,
});
const mapDispatchToProps = {
  getFooterData,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileFooter);
