import * as t from '../types';
import _ from 'lodash';
import axios from './axios-instance-config';
import { API_ROOT } from '../../config';
import DataHelper from '../../config/DataHelper';
import { headers } from '../../config/HeaderHelper';
import countries from '../../data/countries';

import {
  CITIES_API,
  TESTIMONIALS_API,
  GLOBAL_SEARCH_DATA_API,
  HOMEPAGE_PROPERTY_LIST_API,
  GET_DEVELOPERS_API,
  GET_FOOTER_DATA_API,
  LOGIN_API,
  GET_MENUBAR_API,
  GET_MAP_DETAILS,
  LOGOUT_USER_API,
  ADD_WISHLIST_API,
  BLOG_POST_API,
  DISCOUNT_DEAL,
  GET_WISHLISTED_PROPERTIES,
  GET_SEEN_ANDCONTACTED_PROPERTIES,
  GET_LEASE_PROPERTY_COUNT,
  ENQUIRY_FORM_SUBMIT_API,
} from '../../config/EndpointHelper';
import {
  emptyArray,
  emptyObject,
  storeObject,
} from '../../config/LocalStorage';

export const getMenuBar = () => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'menubarReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(API_ROOT + GET_MENUBAR_API);

    dispatch({
      type: t.GET_MENUBAR,
      meta: { reducer: 'menubarReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'menubarReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'menubarReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'menubarReducer' },
      payload: error,
    });
  }
};
export const getmapdetail = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'mapdetailReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(`${API_ROOT}${GET_MAP_DETAILS}`, {
      params: props,
    });
    dispatch({
      type: t.GET_MAP,
      meta: { reducer: 'mapdetailReducer' },
      payload: apiResponse.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'mapdetailReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'mapdetailReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'mapdetailReducer' },
      payload: error,
    });
  }
};

export const getLivabilityProjects = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'livabilityReducer' },
      payload: true,
    });
    const apiResponse = await axios.get(
      `${API_ROOT}${HOMEPAGE_PROPERTY_LIST_API}`,
      { params: props }
    );
    dispatch({
      type: t.GET_LIVABILITY_PROJECTS,
      meta: { reducer: 'livabilityReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'livabilityReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'livabilityReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'livabilityReducer' },
      payload: error,
    });
  }
};

export const updateLivabilityProjects = props => async dispatch => {
  dispatch({
    type: t.GET_LIVABILITY_PROJECTS,
    meta: { reducer: 'livabilityReducer' },
    payload: props.projects,
  });
};

export const nextLivabilityProjects = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'livabilityReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(
      `${API_ROOT}${HOMEPAGE_PROPERTY_LIST_API}`,
      { params: props }
    );
    if (apiResponse.data.data.length > 0) {
      dispatch({
        type: t.NEXT_LIVABILITY_PROJECTS,
        meta: { reducer: 'livabilityReducer' },
        payload: apiResponse.data.data,
      });
    } else {
      dispatch({
        type: t.END_LIVABILITY_PROJECTS,
        meta: { reducer: 'livabilityReducer' },
        payload: apiResponse.data.data,
      });
    }
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'livabilityReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'livabilityReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'livabilityReducer' },
      payload: error,
    });
  }
};

export const getRecommendedProjects = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'recommendedReducer' },
      payload: true,
    });
    const apiResponse = await axios.get(
      `${API_ROOT}${HOMEPAGE_PROPERTY_LIST_API}`,
      { params: props }
    );

    dispatch({
      type: t.GET_RECOMMENDED_PROJECTS,
      meta: { reducer: 'recommendedReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'recommendedReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'recommendedReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'recommendedReducer' },
      payload: error,
    });
  }
};
export const getRecentlyPostedProjects = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'recentlyPostedReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(
      `${API_ROOT}${HOMEPAGE_PROPERTY_LIST_API}`,
      { params: props }
    );
    dispatch({
      type: t.GET_RECENTLY_POSTED_PROPERTIES,
      meta: { reducer: 'recentlyPostedReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'recentlyPostedReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'recentlyPostedReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'recentlyPostedReducer' },
      payload: error,
    });
  }
};

export const nextRecentlyPostedProjects = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'recentlyPostedReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(API_ROOT + HOMEPAGE_PROPERTY_LIST_API, {
      params: props,
    });
    if (apiResponse.data.data.length > 0) {
      dispatch({
        type: t.NEXT_RECENTLY_POSTED_PROPERTIES,
        meta: { reducer: 'recentlyPostedReducer' },
        payload: apiResponse.data.data,
      });
    } else {
      dispatch({
        type: t.END_RECENTLY_POSTED_PROPERTIES,
        meta: { reducer: 'recommendedReducer' },
        payload: apiResponse.data.data,
      });
    }
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'recentlyPostedReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'recentlyPostedReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'recentlyPostedReducer' },
      payload: error,
    });
  }
};
export const nextRecommendedProjects = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'recommendedReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(
      `${API_ROOT}${HOMEPAGE_PROPERTY_LIST_API}`,
      { params: props }
    );
    if (apiResponse.data.data.length > 0) {
      dispatch({
        type: t.NEXT_RECOMMENDED_PROJECTS,
        meta: { reducer: 'recommendedReducer' },
        payload: apiResponse.data.data,
      });
    } else {
      dispatch({
        type: t.END_RECOMMENDED_PROJECTS,
        meta: { reducer: 'recommendedReducer' },
        payload: apiResponse.data.data,
      });
    }
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'recommendedReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'recommendedReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'recommendedReducer' },
      payload: error,
    });
  }
};

export const getDevelopers = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'developersReducer' },
      payload: true,
    });

    await axios
      .get(API_ROOT + GET_DEVELOPERS_API, { params: props })
      .then(res => {
        dispatch({
          type: t.GET_DEVELOPERS,
          meta: { reducer: 'developersReducer' },
          payload: { developers: res.data.data, count: res.data.count },
        });
      })
      .catch(err => {
        dispatch({
          type: t.ERROR,
          meta: { reducer: 'developersReducer' },
          payload: err.response.data.message,
        });
      });

    dispatch({
      type: t.LOADING,
      meta: { reducer: 'developersReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'developersReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'developersReducer' },
      payload: error,
    });
  }
};

export const updateDevelopers = props => async dispatch => {
  try {
    dispatch({
      type: t.GET_DEVELOPERS,
      meta: { reducer: 'developersReducer' },
      payload: props,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'developersReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'developersReducer' },
      payload: error,
    });
  }
};

export const nextDevelopers = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'developersReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(API_ROOT + GET_DEVELOPERS_API, {
      params: props,
    });
    if (apiResponse.data.data.length > 0) {
      dispatch({
        type: t.NEXT_DEVELOPERS,
        meta: { reducer: 'developersReducer' },
        payload: {
          developers: apiResponse.data.data,
          count: apiResponse.data.count,
        },
      });
    } else {
      alert('trigger');
      dispatch({
        type: t.END_NEXT_DEVELOPERS,
        meta: { reducer: 'developersReducer' },
        payload: {
          developers: apiResponse.data.data,
          count: apiResponse.data.count,
        },
      });
    }

    dispatch({
      type: t.LOADING,
      meta: { reducer: 'developersReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'developersReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'developersReducer' },
      payload: error,
    });
  }
};

export const getTestimonials = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'testimonialsReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(API_ROOT + TESTIMONIALS_API, {
      params: props,
    });

    dispatch({
      type: t.GET_TESTIMONIALS,
      meta: { reducer: 'testimonialsReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'testimonialsReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'testimonialsReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'testimonialsReducer' },
      payload: error,
    });
  }
};

export const getCities = () => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(API_ROOT + CITIES_API);

    dispatch({
      type: t.GET_CITIES,
      meta: { reducer: 'headerReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'headerReducer' },
      payload: error,
    });
  }
};

export const getGlobalSearchData = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(API_ROOT + GLOBAL_SEARCH_DATA_API, {
      params: props,
    });
    dispatch({
      type: t.GET_GLOBAL_SEARCH_DATA,
      meta: { reducer: 'headerReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'headerReducer' },
      payload: error,
    });
  }
};

export const setGlobalSelectedCity = (city, label) => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: true,
    });

    if (city) {
      emptyObject(label);
      storeObject(label, city);
    }
    dispatch({
      type: t.SET_GLOBAL_SELECTED_CITY,
      meta: { reducer: 'headerReducer' },
      payload: city,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'headerReducer' },
      payload: error,
    });
  }
};

export const setGlobalSelectedCities = (city, label) => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: true,
    });

    if (city) {
      emptyObject(label);
      storeObject(label, city);
    }
    dispatch({
      type: t.SET_GLOBAL_SELECTED_CITIES,
      meta: { reducer: 'headerReducer' },
      payload: city,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'headerReducer' },
      payload: error,
    });
  }
};

export const setDeveloperIds = (id, label) => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: true,
    });

    if (id) {
      emptyArray(label);
      storeObject(label, id);
    }
    dispatch({
      type: t.SET_DEVELOPER_IDS,
      meta: { reducer: 'headerReducer' },
      payload: id,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'headerReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'headerReducer' },
      payload: error,
    });
  }
};

// @TO-DO : Adding key for conditional rendering of header
export const setCurrentPageType = pageName => async dispatch => {
  try {
    if (pageName) {
      dispatch({
        type: t.OPEN_HEADER_BY_PAGENAME,
        meta: { reducer: 'headerReducer' },
        payload: pageName,
      });
    }
  } catch (error) {
    dispatch({
      type: t.OPEN_HEADER_BY_PAGENAME,
      meta: { reducer: 'headerReducer' },
      payload: '',
    });
  }
};
// @TO-DO : Adding key for conditional rendering of header
export const setCurrentCity = currentCity => async dispatch => {
  try {
    if (currentCity) {
      dispatch({
        type: t.SET_CURRENT_CITY,
        meta: { reducer: 'headerReducer' },
        payload: currentCity,
      });
    }
  } catch (error) {
    dispatch({
      type: t.SET_CURRENT_CITY,
      meta: { reducer: 'headerReducer' },
      payload: '',
    });
  }
};

export const getFooterData = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'footerReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(API_ROOT + GET_FOOTER_DATA_API, {
      params: props,
    });

    dispatch({
      type: t.GET_ALL_FOOTER_DATA,
      meta: { reducer: 'footerReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'footerReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'footerReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'footerReducer' },
      payload: error,
    });
  }
};

export const logoutUserApi = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'loginReducer' },
      payload: true,
    });

    await axios
      .get(API_ROOT + LOGOUT_USER_API, {
        headers: _.merge(headers, props.headers),
      })
      .then(res => {})
      .catch(err => {
        dispatch({
          type: t.ERROR,
          meta: { reducer: 'loginReducer' },
          payload: _.merge(err.response.data.message, {
            responseCode: err.response.status,
          }),
        });
      });

    dispatch({
      type: t.LOADING,
      meta: { reducer: 'loginReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'loginReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'loginReducer' },
      payload: error,
    });
  }
};

export const loginSubmit = props => async dispatch => {
  try {
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'loginReducer' },
      payload: null,
    });

    dispatch({
      type: t.LOADING,
      meta: { reducer: 'loginReducer' },
      payload: true,
    });
    if (props?.email == 'smith12@gmail.com') {
      dispatch({
        type: t.ERROR,
        meta: { reducer: 'loginReducer' },
        payload: _.merge("Invalid User...", {
          responseCode: 500,
        }),
      });
    } else {
    await axios
      .post(API_ROOT + LOGIN_API, DataHelper(props), {
        headers: headers,
      })
      .then(res => {
        dispatch({
          type: t.LOGIN_SUBMIT,
          meta: { reducer: 'loginReducer' },
          payload: _.merge(res.data, {
            responseCode: res.status,
            loading: false,
          }),
        });
      })
      .catch(err => {
        dispatch({
          type: t.ERROR,
          meta: { reducer: 'loginReducer' },
          payload: _.merge(err.response.data.message, {
            responseCode: err.response.status,
          }),
        });
      });
    }
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'loginReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'loginReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'loginReducer' },
      payload: error,
    });
  }
};

export const getCountries = () => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'countriesReducer' },
      payload: true,
    });

    dispatch({
      type: t.GET_ALL_COUNTRIES,
      meta: { reducer: 'countriesReducer' },
      payload: countries,
    });

    dispatch({
      type: t.LOADING,
      meta: { reducer: 'countriesReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'countriesReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'countriesReducer' },
      payload: error,
    });
  }
};

export const getShortlistedproperties = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'getShortlistedpropertiesReducer' },
      payload: true,
    });

    await axios
      .get(`${API_ROOT}${GET_WISHLISTED_PROPERTIES}`, { params: props })
      .then(res => {
        dispatch({
          type: t.GET_SHORTLISTED_PROPERTIES,
          meta: { reducer: 'getShortlistedpropertiesReducer' },
          payload: res.data,
        });
      })
      .catch(err => {
        dispatch({
          type: t.ERROR,
          meta: { reducer: 'getShortlistedpropertiesReducer' },
          payload: err.response?.data?.message,
        });
      });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'getShortlistedpropertiesReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'getShortlistedpropertiesReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'getShortlistedpropertiesReducer' },
      payload: error,
    });
  }
};

export const getSeenandContactedproperties = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'getSeenandContactedpropertiesReducer' },
      payload: true,
    });

    await axios
      .get(`${API_ROOT}${GET_SEEN_ANDCONTACTED_PROPERTIES}`, { params: props })
      .then(res => {
        dispatch({
          type: t.GET_SEENANDCONTACTED_PROPERTIES,
          meta: { reducer: 'getSeenandContactedpropertiesReducer' },
          payload: res.data.data,
        });
      })
      .catch(err => {
        dispatch({
          type: t.ERROR,
          meta: { reducer: 'getSeenandContactedpropertiesReducer' },
          payload: err.response.data.message,
        });
      });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'getSeenandContactedpropertiesReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'getSeenandContactedpropertiesReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'getSeenandContactedpropertiesReducer' },
      payload: error,
    });
  }
};

export const addWishlist = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'addWishlistReducer' },
      payload: true,
    });

    await axios
      .post(API_ROOT + ADD_WISHLIST_API, DataHelper(props.data), {
        headers: _.merge(headers, props.headers),
      })
      .then(res => {
        try {
          if (props.callBack !== undefined) props.callBack();
        } catch (e) {
          console.log('error on function call -- Wishlist might not update.');
        }
        dispatch({
          type: t.ADD_WISHLIST,
          meta: { reducer: 'addWishlistReducer' },
          payload: res.data,
        });
      })
      .catch(err => {
        dispatch({
          type: t.ERROR,
          meta: { reducer: 'addWishlistReducer' },
          payload: err.response.data.message,
        });
      });

    dispatch({
      type: t.LOADING,
      meta: { reducer: 'addWishlistReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'addWishlistReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'addWishlistReducer' },
      payload: error,
    });
  }
};

export const geBlogPostProjects = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'BlogPostReducer' },
      payload: true,
    });
    const apiResponse = await axios.get(API_ROOT + BLOG_POST_API, {
      params: props,
    });

    dispatch({
      type: t.GET_BLOGPOST_PROJECTS,
      meta: { reducer: 'BlogPostReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'BlogPostReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'BlogPostReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'BlogPostReducer' },
      payload: error,
    });
  }
};

export const nextBlogPostProjects = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'BlogPostReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(API_ROOT + BLOG_POST_API + '?' + props);
    dispatch({
      type: t.NEXT_BLOGPOST_PROJECTS,
      meta: { reducer: 'BlogPostReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'BlogPostReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'BlogPostReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'BlogPostReducer' },
      payload: error,
    });
  }
};

export const getDiscountDealProjects = props => async dispatch => {
  let apiResponse = {};
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'investmentReducer' },
      payload: true,
    });
    try {
      apiResponse = await axios.get(`${API_ROOT}${DISCOUNT_DEAL}`, {
        params: props,
      });
    } catch (e) {
      console.log('apiResponse==error==', e);
    }
    dispatch({
      type: t.GET_INVESTMENT_PROJECTS,
      meta: { reducer: 'investmentReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'investmentReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'investmentReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'investmentReducer' },
      payload: error,
    });
  }
};

export const nextDiscountDealProjects = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'investmentReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(`${API_ROOT}${DISCOUNT_DEAL}`, {
      params: props,
    });
    if (apiResponse.data.data.length > 0) {
      dispatch({
        type: t.NEXT_INVESTMENT_PROJECTS,
        meta: { reducer: 'investmentReducer' },
        payload: apiResponse.data.data,
      });
    } else {
      dispatch({
        type: t.END_INVESTMENT_PROJECTS,
        meta: { reducer: 'investmentReducer' },
        payload: apiResponse.data.data,
      });
    }

    dispatch({
      type: t.LOADING,
      meta: { reducer: 'investmentReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'investmentReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'investmentReducer' },
      payload: error,
    });
  }
};

export const setSearchPageProps = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'searchPagePropsReducer' },
      payload: true,
    });

    dispatch({
      type: t.SET_SEARCH_PAGE_PROPS,
      meta: { reducer: 'searchPagePropsReducer' },
      payload: props,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'searchPagePropsReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'searchPagePropsReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'searchPagePropsReducer' },
      payload: error,
    });
  }
};

export const getLeaseTypePropertyCount = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'leaseTypeCountReducer' },
      payload: true,
    });

    const apiResponse = await axios.get(API_ROOT + GET_LEASE_PROPERTY_COUNT, {
      params: props,
    });

    dispatch({
      type: t.SET_LEASE_PROPERTY_COUNT,
      meta: { reducer: 'leaseTypeCountReducer' },
      payload: apiResponse.data.data,
    });
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'leaseTypeCountReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'leaseTypeCountReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'leaseTypeCountReducer' },
      payload: error,
    });
  }
};

// ****** common api call function for enquiry all form *******

export const callEnquiryFormApi = async ({ data, onSuccess, onError }) => {
  console.log('data==', data);
  if (data?.phone == '8655413718' || data.email == 'smith12@gmail.com') {
    if (onError) onError({ data: { message: 'Something went wrong...' } });
  } else {
    await axios
      .post(API_ROOT + ENQUIRY_FORM_SUBMIT_API, DataHelper(data), {
        headers: headers,
      })
      .then(response => {
        if (response.status === 200 || response.status === 202) {
          if (onSuccess) onSuccess(response);
        } else {
          if (onError) onError(response);
        }
      })
      .catch(error => {
        console.error('API Error:', error);
        if (onError)
          onError(
            error.response || { data: { message: 'Something went wrong...' } }
          );
      });
  }
};
