import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ClassNames from 'classnames';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import { useForm } from '../useForm';
import { connect } from 'react-redux';
import { callEnquiryFormApi, getCountries } from '../../../../redux/actions/main';
import { enquiryFormSubmit } from '../../../../redux/actions/url';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { MYACCOUNT_HOMEBAZAAR } from '../../../../config';
import axios from 'axios';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import useUserInfo from '../../../packages/use-user-info';
import useUserIp from '../../../packages/use-user-ip';
import useUtmParams from '../../../../Hooks/useUtmParams';
import UtmParamsInput from '../UtmParamsInput';
import LeadOptForm from '../LeadOtpForm';
import PopupDialogBox from '../../../packages/popup-dialog-box';
import { storeItem, storeObject } from '../../../../config/LocalStorage';
import HBTextField from '../../../../UI/FormInputs/textFeild/HBTextField';
import { contactedSeenPropertyApicall } from '../../../../redux/actions/propertyDetails';
import Variables from 'styles/variables.module.scss';
import breakpoints from '../../../../data/breakpoints.json';
// import styles from './enquiryform.module.scss';
import Link from 'next/link';
const PREFIX = 'index';

const custom_classes = {
  root: `${PREFIX}-root`,
  formSubmit: `${PREFIX}-formSubmit`,
  pdmain: `${PREFIX}-pdmain`,
  noRightBorderRadius: `${PREFIX}-noRightBorderRadius`,
  noLeftBorder: `${PREFIX}-noLeftBorder`,
  selectHeight: `${PREFIX}-selectHeight`,
  headingtitle: `${PREFIX}-headingtitle`,
  textInputSelect: `${PREFIX}-textInputSelect`,
  pherror: `${PREFIX}-pherror`,
  formWarpper: `${PREFIX}-formWarpper`,
  formWarpperdev: `${PREFIX}-formWarpperdev`,
  formNoWarpperdev: `${PREFIX}-formNoWarpperdev`,
  homeLoanCheckBox: `${PREFIX}-homeLoanCheckBox`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${custom_classes.root}`]: {
    padding: '5px',
    background: '#fff',
    borderRadius: '10px',
    border: '1px solid #D3E0ED',
    [theme.breakpoints.down(breakpoints.sm)]: {
      borderRadius: '12px 12px 0px 0px',
      border: 'none',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px !important',
      marginRight: '0px !important',
      fontSize: '13px',
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginRight: '7px',
      },
    },
    '& .MuiAutocomplete-root .css-bckmzb-MuiAutocomplete-popper': {
      zIndex: '99999 !important',
    },
  },

  [`& .${custom_classes.formSubmit}`]: {
    width: '100%',
    backgroundColor: '#0056B2',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    transition: 'all 0.3s ease-in-out',
    height: '50px',
    marginTop: '16px',
    marginBottom: '20px',
    borderRadius: '7px!important',
    font: 'normal normal bold 18px/24px Open Sans',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#0056B2',
      color: '#FFFFFF',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '7px!important',
      height: '50px',
      font: 'normal normal bold 20px/27px Open Sans',
    },
  },

  [`& .${custom_classes.pdmain}`]: {
    padding: '0px 26px 0px 26px',
  },

  [`& .${custom_classes.noRightBorderRadius}`]: {
    '& .MuiFilledInput-inputMarginDense': {
      border: '1px solid #99A2AC',
      paddingTop: '36px',
      borderRadius: '7px',
      paddingBottom: '10px',
      backgroundColor: '#fff',
      // borderBottomRightRadius: '0px',
      // borderTopRightRadius: '0px',
      // borderRight: 'none',
    },
    '& .MuiSelect-iconFilled': {
      right: '40%',
      top: '50%',
    },
  },

  [`& .${custom_classes.noLeftBorder}`]: {
    '& .HBTextField-textInput': {
    // '& .MuiFilledInput-inputMarginDense': {
      borderBottomLeftRadius: '0px',
      borderTopLeftRadius: '0px',
    },
  },

  [`& .${custom_classes.selectHeight}`]: {
    '& .MuiFilledInput-inputMarginDense': {
      paddingTop: '15px',
      height: '42px',
      color: '#333333',
    },
  },

  [`& .${custom_classes.headingtitle}`]: {
    color: '#171717',
    fontSize: '16px',
    fontFamily: 'Open Sans!important',
    fontWeight: '600',
    textAlign: 'center',
    margin: '10px 0 17px 0',
    lineHeight: '22px',
    letterSpacing: '0px',
    [theme.breakpoints.down(breakpoints.smm)]: {
      width: '100%',
      padding: '0px',
      fontSize: '13px',
      fontWeight: '700',
      color: '#171717',
      lineHeight: '18px',
    },
  },

  [`& .${custom_classes.textInputSelect}`]: {
    '& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
      top: '1px',
    },
  },

  [`& .${custom_classes.pherror}`]: {
    display: 'flex',
    alignItems: 'center',
    color: '#f44336',
    marginBottom: '7px',
    fontSize: '13px',
    fontFamily: 'Open Sans, sans-serif !important',
    '& img': {
      marginRight: '7px',
    },
  },

  [`& .${custom_classes.formWarpper}`]: {
    height: 'calc( 85vh - 150px )',
    overflow: 'auto',
    paddingTop: '0px',
    maxHeight: '400px',
    [theme.breakpoints.up(breakpoints.xlx)]: {
      maxHeight: '400px',
    },
    [theme.breakpoints.down(breakpoints.sm)]: {
      // height: 'calc(100vh - 30px ) !important',
      overflow: 'auto',
      paddingTop: '0px',
      maxHeight: '100%',
      background: '#fff',
    },
  },

  [`& .${custom_classes.formWarpperdev}`]: {
    height: 'calc( 88vh - 150px )',
    overflow: 'auto',
    paddingTop: '0px',
    maxHeight: '475px',
    [theme.breakpoints.up(breakpoints.xlx)]: {
      // maxHeight: '475px',
    },
    [theme.breakpoints.down(breakpoints.sm)]: {
      // height: 'calc(100vh - 30px ) !important',
      overflow: 'auto',
      paddingTop: '0px',
      height: '475px',
      background: '#fff',
    },
  },

  [`& .${custom_classes.formNoWarpperdev}`]: {
    height: 'calc( 85vh - 150px )',
    overflow: 'auto',
    paddingTop: '0px',
    maxHeight: '400px',
    [theme.breakpoints.up(breakpoints.xlx)]: {
      maxHeight: '400px',
    },
    [theme.breakpoints.down(breakpoints.sm)]: {
      overflow: 'auto',
      paddingTop: '0px',
      height: '440px',
      background: '#fff',
    },
  },

  [`& .${custom_classes.homeLoanCheckBox}`]: {
    color: '#171717',
    font: 'normal normal normal 14px/19px Open Sans',
    marginLeft: '2px',
    marginTop: '15px',
    display: 'flex',
    paddingBottom: '10px',
  }
}));

const EnquiryForm = props => {
  const {
    projects = [],
    root_style,
    root_class,
    heading,
    buttonHeading,
    property_id,
    is_whatsapp,
    buildersPage,
    hideHomeLoanCheck,
    module_type,
  } = props;

  const [Country, setCountry] = useState('India|+91');
  const [CountryCode, setCountryCode] = useState('+91');
  const handleChangeNew = event => {
    let countryVal = event.target.value;
    setCountry(countryVal);
    setCountryCode(countryVal.split('|')[1]);
  };
  const [success, setSuccess] = useState({ msg: '', class: '' });
  const [error, setError] = useState('');
  const [ip_address] = useUserIp();
  const [form_loading, setFormLoading] = useState(false);
  const [user] = useUserInfo();
  const [url, setUrl] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [homeLoanCheck, setHomeLoanCheck] = useState(false);
  const [checkBoxErr, setCheckBoxErr] = useState(false);
  const [isUser, setIsUser] = useState(false);
  useEffect(() => {
    const isLogin = !(
      user === null ||
      user === undefined ||
      Object.values(user).length == 0
    );
    setIsUser(isLogin);
    if (isLogin) {
      setValues({
        name: user?.name ?? '',
        phone: user?.phone ?? '',
        email: user?.email ?? '',
      });
    }
  }, [user]);
  let initialFValues = {
    name: '',
    phone: '',
    email: '',
    country_code: '',
    country: '',
    module_type: '',
  };
  const inputStyle = { WebkitBoxShadow: '0 0 0 1000px white inset' };
  const handleCallback = childData => {
    setSuccess({ msg: childData, class: 'thankyou-text' });
  };
  let logged_out = !isUser;

  const utmParams = useUtmParams();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ('name' in fieldValues) {
      if (fieldValues.name) {
        if (fieldValues.name.length <= 25) {
          if (/^[a-zA-Z ]*$/.test(fieldValues.name)) {
            temp.name = ''; // Valid name
          } else {
            temp.name = (
              (<Root>
                <img src="/static/images/error-form.svg" alt="error-form" />Please enter a valid name.
              </Root>)
            );
          }
        } else {
          temp.name = (
            <>
              <img src="/static/images/error-form.svg" alt="error-form" />
              The name must be 25 characters or less.
            </>
          );
        }
      } else {
        temp.name = (
          <>
            <img src="/static/images/error-form.svg" alt="error-form" />
            Please enter a name.
          </>
        );
      }
    }

    if ('phone' in fieldValues) {
      if (CountryCode === '+91') {
        if (fieldValues.phone) {
          temp.phone =
            /^\d*$/.test(fieldValues.phone) &&
              fieldValues.phone.length === 10 ? (
              ''
            ) : (
              <>
                <img src="/static/images/error-form.svg" alt="error-form" />{' '}
                Please enter a valid phone number.
              </>
            );
        } else {
          temp.phone = (
            <>
              <img src="/static/images/error-form.svg" alt="error-form" />{' '}
              Please enter a phone number.
            </>
          );
        }
      } else if (CountryCode !== '+91') {
        if (fieldValues.phone) {
          temp.phone =
            fieldValues.phone.length > 0 ? (
              ''
            ) : (
              <>
                <img src="/static/images/error-form.svg" alt="error-form" />{' '}
                Please enter a phone number.
              </>
            );
        } else {
          temp.phone = (
            <>
              <img src="/static/images/error-form.svg" alt="error-form" />{' '}
              Please enter a phone number.
            </>
          );
        }
      }
    }

    if ('email' in fieldValues) {
      if (fieldValues.email) {
        temp.email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
          fieldValues.email
        ) ? (
          ''
        ) : (
          <>
            <img src="/static/images/error-form.svg" alt="error-form" /> Please
            enter a valid email.
          </>
        );
      } else {
        temp.email = (
          <>
            <img src="/static/images/error-form.svg" alt="error-form" /> Please
            enter an email address.
          </>
        );
      }
    }

    if ('project_id' in fieldValues && projects.length)
      temp.project_id = fieldValues.project_id ? (
        ''
      ) : (
        <>
          <img src="/static/images/error-form.svg" alt="error-form"></img>{' '}
          Please select a project.
        </>
      );

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every(x => x == '');
  };
  const setMyToken = () => {
    axios.get(
      `${MYACCOUNT_HOMEBAZAAR}ajax_login?goTopage=${btoa(
        values.email
      )}&comeFrompage=${btoa(values.phone)}`,
      { withCredentials: true }
    );
  };
  function closeModal() {
    setOpenPopup(!openPopup);
  }

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const validation = e => {
    if (termsCheck) {
      setCheckBoxErr(false);
      handleSubmit(e);
    } else {
      e.preventDefault();
      setCheckBoxErr(true);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    validate();
    let country = Country.split('|') || ['91', 'India'];
    _.merge(values, { url: url });
    _.merge(values, { type: 0 });
    property_id && _.merge(values, { project_id: property_id });
    _.merge(values, { country: country[0] });
    _.merge(values, { country_code: country[1] });
    _.merge(values, { gclid: utmParams.gclid ?? '' });
    _.merge(values, { fbclid: utmParams.fbclid ?? '' });
    _.merge(values, {
      utm_source: utmParams.utm_source ?? '',
    });
    _.merge(values, {
      utm_medium: utmParams.utm_medium ?? '',
    });
    _.merge(values, {
      utm_campaign: utmParams.utm_campaign ?? '',
    });
    _.merge(values, {
      utm_term: utmParams.utm_term ?? '',
    });
    _.merge(values, { logged_in: !logged_out });
    _.merge(values, { ip: ip_address });
    _.merge(values, { applied_homeloan: homeLoanCheck === true ? 1 : 0 });
    _.merge(values, { enquiredfor: 'Contact Us' });
    _.merge(values, { module_type: module_type });
    if (validate()) {
      setFormLoading(true);
      setError('');
      setSuccess({ msg: '', class: '' });
      // await axios
        // .post(API_ROOT + ENQUIRY_FORM_SUBMIT_API, DataHelper(values), {
        //   headers: headers,
        // })
        await callEnquiryFormApi({
          data: values, 
          onSuccess: (response) => {
            if (response.status === 200) {
              if (logged_out) {
                setSuccess({ msg: response.data.message, class: 'otp-text' });
              } else {
                setSuccess({ msg: response.data.message, class: 'thankyou-text' });
              }
              storeItem('access_token', response?.data?.access_token || '');
              storeObject('user', response?.data?.user || {});
      
              if (country[1] !== '+91') {
                setMyToken();
              }
              if (logged_out) setOpenPopup(true);
              else {
                contactedSeenPropertyApiCall(
                  module_type,
                  response?.data?.user || {},
                  property_id
                );
              }
            } else if (response.status === 202) {
              setError(response.data.email);
              setError(response.data.message);
            }
            setFormLoading(false);
          },
          onError: (errorResponse) => {
            setError(errorResponse?.data?.message || 'Something went wrong...');
            setFormLoading(false);
          },
        });
        // await callEnquiryFormApi(values)
        // .then(async response => {
        //   if (response.status == 200) {
        //     if (logged_out) {
        //       setSuccess({ msg: response.data.message, class: 'otp-text' });
        //     } else {
        //       setSuccess({
        //         msg: response.data.message,
        //         class: 'thankyou-text',
        //       });
        //     }
        //     storeItem('access_token', response?.data?.access_token || '');
        //     storeObject('user', response?.data?.user || {});

        //     if (country[1] !== '+91') {
        //       setMyToken();
        //     }
        //     if (logged_out) setOpenPopup(true);
        //     else {
        //       contactedSeenPropertyApiCall(
        //         module_type,
        //         response?.data?.user || {},
        //         property_id
        //       );
        //     }
        //   } else if (response.status == 202) {
        //     setError(response.data.email);
        //     setError(response.data.message);
        //   }
        //   setFormLoading(false);
        // })
        // .catch(() => {
        //   setError('Something went wrong...');
        //   setFormLoading(false);
        // });
    }
  };

  const onInput = e => {
    if (CountryCode === '+91')
      e.target.value = Math.max(0, parseInt(e.target.value))
        .toString()
        .slice(0, 10);
  };
  const { countries } = props.countriesApiData;

  useEffect(() => {
    setUrl(window.location.href);
    let mounted = false;
    if (!mounted) {
      if (countries?.length == 0) {
        props.getCountries();
      }
    }
    return;
  }, []);

  useEffect(() => {
    if (user) {
      const updatedValues = { ...values };
      updatedValues.name = user.name || '';
      updatedValues.phone = user.phone || '';
      updatedValues.country_code = user.country_code || '+91';
      updatedValues.email = user.email || '';
      updatedValues.country = user.country || '';
      updatedValues.module_type = module_type;
      setValues(updatedValues);
      setCountryCode(updatedValues.country_code);
      let countryObject = countries.find(
        countryItem =>
          countryItem.countryCodevalue === updatedValues.country_code
      );
      if (!countryObject) {
        countryObject = {
          countryCodelabel: updatedValues.country_code,
          countryCodevalue: updatedValues.country_code,
          countryName: 'India',
          countryIsoCode: 'IN',
        };
      }
      setCountry(
        `${countryObject.countryName}|${countryObject.countryCodevalue}`
      );
    }
  }, [user]);

  const contactedSeenPropertyApiCall = (
    module_type_contacted,
    user_contacted,
    property_id_contacted
  ) => {
    props.contactedSeenPropertyApicall({
      module_type: module_type_contacted,
      user_id: user_contacted?.id,
      property_id: property_id_contacted,
      category: 'contacted',
    });
  };

  return (
  <Root>
    <div className={ClassNames([custom_classes.root, root_class])} style={root_style}>
      <h5 className={custom_classes.headingtitle}>
        {heading ?? 'Get Instant Call Back Now'}
      </h5>

      <form
        className={
          buildersPage === 'buildersPage'
            ? projects.length === 0
              ? custom_classes.formNoWarpperdev
              : custom_classes.formWarpperdev
            : custom_classes.formWarpper
        }
        onSubmit={validation}>
        <div className={custom_classes.pdmain}>
          <HBTextField
            data-cy={'name-input'}
            name="name"
            id="name"
            label="Name"
            isdisabled={user && user?.name ? true : false}
            variant="filled"
            style={{ width: '100%' }}
            value={values.name}
            onChange={handleInputChange}
            // error={errors.name}
            helperText={errors.name}
            inputProps={{ style: inputStyle, maxLength: 25 }}
            />
            <span className={custom_classes.pherror}>{errors.name}</span>
          <Grid container justifyContent="flex-start" alignitems="center">
            <Grid size={5}>
              <HBTextField
                className={custom_classes.noRightBorderRadius}
                noRightBorderRadius={true}
                data-cy={'country-input'}
                disabled={user && user?.name ? true : false}
                id="country"
                name="country"
                label="Country Code"
                InputLabelProps={{ shrink: Country ? true : false }}
                style={{
                  width: '101%',
                  fontWeight: '400!important',
                  fontSize: '16px'
                }}
                select
                value={CountryCode}
                onChange={handleChangeNew}
                SelectProps={{
                  native: true,
                }}
                variant="filled">
                {countries?.map(data => (
                  <option
                    key={data.countryCodelabel}
                    value={data.countryCodelabel}
                    hidden>
                    {(user && user?.country_code) || data.countryCodelabel}
                  </option>
                ))}
                {countries?.map(data => (
                  <option
                    key={data.countryCodelabel}
                    value={`${data.countryName}|${data.countryCodelabel}`}>
                    {data.countryName}|({data.countryCodelabel})
                  </option>
                ))}
              </HBTextField>
            </Grid>
            <Grid size={7}>
              <HBTextField
                className={custom_classes.noLeftBorder}
                data-cy={'phone-input'}
                name="phone"
                isdisabled={isUser}
                id="phone"
                type="number"
                label="Phone"
                variant="filled"
                style={{ width: '100%' }}
                value={values.phone}
                onChange={handleInputChange}
                inputProps={{ style: inputStyle }}
                onInput={onInput}
                noLeftBorder={true}
              />
            </Grid>
            <span className={custom_classes.pherror}>{errors.phone}</span>
          </Grid>

          <div className={custom_classes.textInput}>
            <HBTextField
              data-cy={'email-input'}
              name="email"
              id="email"
              label="Email"
              variant="filled"
              style={{ width: '100%' }}
              disabled={isUser}
              value={values.email}
              onChange={handleInputChange}
              // error={errors.email}
              helperText={errors.email}
              inputProps={{ style: inputStyle }}
              />
              <span className={custom_classes.pherror}>{errors.email}</span>
          </div>

          {projects.length ? (
            <div className={custom_classes.textInputSelect}>
              <HBTextField
                style={{
                  width: '100%',
                }}
                select
                value={values.project_id}
                SelectProps={{
                  native: true,
                }}
                className={custom_classes.selectHeight}
                variant="filled"
                name="project_id"
                label={
                  values.project_id === '' || values.project_id === undefined
                    ? ''
                    : 'Project'
                }
                onChange={handleInputChange}
                // error={errors.project_id}
                >
                <option value="">Select Project</option>
                {projects?.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
                </HBTextField>
                <span className={custom_classes.pherror}>{errors.project_id}</span>
            </div>
          ) : (
            ''
          )}

          {is_whatsapp && (
            <div className={custom_classes.textInput}>
              <TextareaAutosize
                minRows={3}
                disabled={true}
                placeholder={`Hi! I'm Interested in ${heading}. Please Share Details.`}
              />
            </div>
          )}
          <UtmParamsInput />
          <div className={custom_classes.textInput}>
            <div
              style={{
                color: Variables.darkColor,
                font: 'normal normal normal 14px/19px Open Sans',
                marginLeft: '2px',
                marginBottom: '15px',
                display: 'flex',
                marginTop: '1.3rem',
              }}>
              {!termsCheck && (
                <img
                  src="/static/images/checkbox.svg"
                  width="16px"
                  height="19px"
                  alt="checkbox"
                  onClick={() => {
                    setTermsCheck(true);
                    setCheckBoxErr(false);
                  }}
                />
              )}
              {termsCheck && (
                <img
                  src="/static/images/icon-feather-check-square.svg"
                  width="16px"
                  height="19px"
                  alt="icon-feather-check-square"
                  onClick={() => setTermsCheck(false)}
                  required={true}
                />
              )}
              <div
                style={{
                  marginLeft: '10px',
                  display: 'flex',
                }}>
                I accept{' '}
                <Link
                  href={'/privacy-policy'}
                  target="_blank"
                  style={{ color: '#0056b2' }}>
                  {' '}
                  &nbsp;T&C and Privacy Policy{' '}
                </Link>
              </div>
            </div>
          </div>
          {hideHomeLoanCheck ||
            (module_type === 'buy' && (
              <div className={custom_classes.homeLoanCheckBox}>
                <img
                  src={
                    homeLoanCheck
                      ? '/static/images/icon-feather-check-square.svg'
                      : '/static/images/checkbox.svg'
                  }
                  alt="chekcbox"
                  width="16px"
                  height="19px"
                  onClick={() => setHomeLoanCheck(!homeLoanCheck)}
                />
                <div
                  style={{
                    marginLeft: '10px',
                    display: 'flex',
                    fontWeight: '400',
                    fontSize: '14px',
                  }}>
                  I am interested in Home Loans
                </div>
              </div>
            ))}
          {success.msg != '' && (
            <h5 className={success.class}>{success.msg}</h5>
          )}

          {error != '' && <h5 className="error-text-upd">{error}</h5>}

          {checkBoxErr != '' && (
            <h5 className="error-text-upd">
              <img src="/static/images/error-form.svg" alt="error-form"></img>{' '}
              {'Please select the T&C and Privacy Policy'}
            </h5>
          )}

          {form_loading && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <CircularProgress />
            </div>
          )}

          <Button
            data-cy="request-info-btn"
            type="submit"
            className={ClassNames(['enquiry_class', custom_classes.formSubmit])}
            variant="contained">
            {buttonHeading ?? 'Get Instant Call Back'}
          </Button>
        </div>
      </form>
    </div>
    {success.msg === 'OTP created Successfully' && (
      <PopupDialogBox
        root_class="custom-modal"
        open={openPopup}
        close={closeModal}
        modal_content={
          <LeadOptForm
            setMyToken={setMyToken}
            Country={Country}
            values={values}
            url={url}
            property_id={property_id}
            enquiredFor={'Contact Us'}
            parentCallback={handleCallback}
            setOpenPopup={setOpenPopup}
          />
        }
      />
    )}
  </Root>);
};

const mapStateToProps = state => ({
  countriesApiData: state.countriesReducer,
  enquiryApiData: state.enquiryFormReducer,
});

const mapDispatchToProps = {
  getCountries,
  enquiryFormSubmit,
  contactedSeenPropertyApicall,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnquiryForm);
