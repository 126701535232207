import React, { useState, useEffect } from 'react';
import handleViewport from 'react-in-viewport';
import {
  getDiscountDealProjects,
  nextDiscountDealProjects,
  addWishlist,
} from '../../../redux/actions/main';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid2';
import CommonHeaderText from '../../packages/common-header-text';
import ViewAll from '../../Desktop/Common/ViewAll';
import Skeleton from '@mui/material/Skeleton';
import {
  capitalizeFirstLetter,
  slugify,
  gotoPropertyDetailsPage,
  searchURLType,
  getSearchPageURL,
} from '../../util';
import ClassNames from 'classnames';
// import MobHorizontalScroll from '../../packages/mob-horizontal-scroll';
import MobHorizontalScrollNew from '../../packages/mob-horizontal-scroll-new';
import useUserInfo from '../../packages/use-user-info';
import useAccessToken from '../../packages/use-access-token';
import _ from 'lodash';

import MobNewProjectCard from '../../packages/mob-new-project-card';
import Variables from 'styles/variables.module.scss';
import classes from './mobNewProject.module.scss';
import NextHead from 'next/head';

const highlightedWord = word => {
  return (
    <span
      style={{
        color: Variables.darkorangeColor,
      }}>
      {word}
    </span>
  );
};
// All items component
// Important! add unique key
export const Menu = (
  list,
  loading,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  module_type,
  sliderbtnClass,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger,
  toggleWishlist
) =>
  list &&
  list.length > 0 &&
  _.uniqBy(list, 'pId')
    //TO-CHECK : Commented and to be done by backend
    // .sort((a, b) => (a.weRecommendCount < b.weRecommendCount ? 1 : -1))
    .filter(project => {
      if (project) {
        return true;
      }
    })
    .map((el, index) => {
      return (
        <MenuItem
         cardIndex={index}
          text={el}
          key={index}
          loading={loading}
          open_modal={open_modal}
          propertyDetailsClick={propertyDetailsClick}
          propertyDetailsGallery={propertyDetailsGallery}
          module_type={module_type}
          sliderbtnClass={sliderbtnClass}
          cachedProject={cachedProject}
          wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
          addwishlisttrigger={addwishlisttrigger}
          setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
          deletecachedpropertytrigger={deletecachedpropertytrigger}
          toggleWishlist={toggleWishlist}
        />
      );
    });

const MenuItem = ({
  text,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  module_type,
  sliderbtnClass,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger,
  toggleWishlist,
  cardIndex,
}) => {
  if (text) {
    const {
      pId,
      projectImage,
      projectName,
      weRecommendCount,
      developerName,
      maxPrice,
      minPrice,
      liveabilityIndexCount,
      localityName,
      cityName,
      bestForInvestmentCount,
      streetView,
      videoLink,
      minCarpetArea,
      maxCarpetArea,
      configurationTypeCount,
      offer,
      dealOffer,
      wishlists,
      propetyBuyOrRent,
    } = text;
    const bhk_type_label = Object.keys(configurationTypeCount);
    const bhk_types = bhk_type_label
      .map(data => {
        return configurationTypeCount[data].toString() + ' ' + data;
      })
      .toString();
    const onScheduleTourClick = (propertyid, property_name) => {
      if (open_modal) open_modal(propertyid, property_name, developerName);
    };
    return (
      <MobNewProjectCard
       cardIndex={cardIndex}
        isResale={propetyBuyOrRent === 'resale'}
        maxCarpetArea={maxCarpetArea}
        setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
        deletecachedpropertytrigger={deletecachedpropertytrigger}
        addwishlisttrigger={addwishlisttrigger}
        wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
        cachedProject={cachedProject}
        projectName={projectName}
        livability_index={liveabilityIndexCount}
        investment_index={bestForInvestmentCount}
        locationwithoutCityName={localityName}
        maxPrice={maxPrice}
        minPrice={minPrice}
        priceToshow={
          maxPrice !== minPrice ? minPrice + ' - ' + maxPrice : minPrice
        }
        toggleWishlist={toggleWishlist}
        developer_name={`By ` + developerName}
        bhk_types={bhk_types}
        p_we_recommend_count={weRecommendCount}
        offer={offer}
        dealOffer={dealOffer}
        property_id={pId}
        propertyDetailsClick={propertyDetailsClick}
        propertyDetailsGallery={propertyDetailsGallery}
        property_name={projectName}
        location={localityName}
        scheduleTourClick={onScheduleTourClick}
        sliderbtnClass={sliderbtnClass}
        title={<CommonHeaderText variant="h4" text={projectName} />}
        subheader={
          <CommonHeaderText
            variant="h5"
            text={`by ${developerName}`}
            style={{
              fontSize: '12px',
              color: '#fff',
              fontWeight: '400',
            }}
          />
        }
        sqft={
          <CommonHeaderText
            variant="h5"
            text={minCarpetArea + ` to ` + maxCarpetArea + ` SqFt`}
          />
        }
        details={
          <CommonHeaderText
            variant="h5"
            text={localityName + ', ' + cityName}
          />
        }
        star_rating={weRecommendCount}
        bhk={bhk_types && <CommonHeaderText variant="h5" text={bhk_types} />}
        investmentRating={`${bestForInvestmentCount}/10`}
        livabilityRating={`${liveabilityIndexCount}/10`}
        priceTag={
          minPrice && maxPrice && parseInt(minPrice) && parseInt(maxPrice) ? (
            <CommonHeaderText
              variant="h4"
              text={
                maxPrice !== minPrice ? minPrice + ' - ' + maxPrice : minPrice
              }
            />
          ) : (
            <div
              onClick={event => {
                event.preventDefault(); // Prevent the default click behavior
                open_modal(pId, projectName, false, true);
              }}>
              <CommonHeaderText
                text={'Price On Request'}
                variant="h4"
                style={{
                  cursor: 'pointer',
                  color: Variables.darkColor,
                  fontSize: '14px',
                  fontWeight: '700',
                }}
              />
            </div>
          )
        }
        Images={projectImage}
        ImageWidth="300px"
        ImageHeight="170px"
        street_url={streetView}
        video_url={videoLink}
        module_type={slugify(module_type)}
        cityName={slugify(cityName)}
        wishlists={wishlists}
        squarFit={minCarpetArea + ` to ` + maxCarpetArea + ` SqFt`}
        minCarpetArea={minCarpetArea}
      />
    );
  } else {
    return [];
  }
};

const NewProjectSection = props => {
  let { module_type, sliderbtnClass } = props;
  const {
    forwardedRef,
    open_modal,
    propertyDetailsGallery,
    city,
    cachedProject,
    callWishlistApi,
    wishlistPropertyDeletedCall,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
  } = props;

  const { projects, loading } =
    props.apiData.projects.length > 0
      ? props.apiData
      : { projects: [], loading: false };
  module_type = module_type || 'buy';
  const [access_token] = useAccessToken();
  const toggleWishlist = propertyid => {
    props.addWishlist({
      data: {
        property_id: propertyid,
        user_id: user?.id,
        module_type: module_type,
      },
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
      callBack: callWishlistApi,
    });
    wishlistPropertyDeletedCall();
  };

  const propertyDetailsClick = (property_id, property_name, location) => {
    gotoPropertyDetailsPage({
      module_type: module_type,
      city_name: city?.name,
      property_name: property_name,
      property_id: property_id,
      localityName: location,
    });
  };

  const firstmenu = Menu(
    projects,
    loading,
    open_modal,
    propertyDetailsClick,
    propertyDetailsGallery,
    module_type,
    sliderbtnClass,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    toggleWishlist
  );

  const [user] = useUserInfo();

  const [firstMount, setfirstMount] = useState(false)
  useEffect(() => {
    if (!firstMount) {
      setfirstMount(true)
      return;
    }
    let mounted = false;
    if (!mounted) {
      let userId = user?.id;
      props.getDiscountDealProjects({
        city_id: city?.id,

        module_type: module_type,
        user_id: userId,
      });
    }
    return () => {
      mounted = true;
    };
  }, [city, module_type]);

  // Weekday Logic
  const [weekday, setweekday] = useState();
  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  function getWeekBegin() {
    let now = new Date();
    let next = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + (7 - now.getDay())
    );
    return next;
  }

  useEffect(() => {
    //convert date into required format
    function convert(str) {
      let date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2);

      return [day, months[mnth - 1], date.getFullYear()].join(' ');
    }

    let firstDay = getWeekBegin();
    setweekday(convert(firstDay));
  }, [weekday]);

  if (projects && projects.length == 0) return null;
  return (
    (<section
      className="main-section"
      style={{
        backgroundColor: '#FAFAFA',
      }}
      ref={forwardedRef}>
      <NextHead>
       <link rel="preload" as="image" href={`${projects[0].projectImage[0]}?tr=w-300,h-170`} />
    </NextHead>
      <div className="container">
        <Grid
          justifyContent="space-between"
          container
          alignItems="flex-start"
          className="heading-section">
          <Grid className={classes.headingNewProject}>
            {!projects || (projects.length === 0 && loading) ? (
              <Skeleton
                animation="wave"
                variant="rect"
                width={200}
                height={10}
              />
            ) : (
              <CommonHeaderText
                variant="h2"
                text={
                  <>
                    {highlightedWord('Discounted Deals ')}in{' '}
                    {capitalizeFirstLetter(city?.name)}
                  </>
                }
                color="#000"
              />
            )}
            {!projects || (projects.length === 0 && loading) ? (
              <Skeleton
                animation="wave"
                variant="rect"
                width={200}
                height={10}
              />
            ) : (
              <CommonHeaderText
                text={<>Ends on {weekday}</>}
                size="13px"
                color="#000"
              />
            )}
          </Grid>
          <Grid>
            <ViewAll
              text="View All"
              url={getSearchPageURL(
                searchURLType.discountedDeals,
                module_type,
                city?.name
              )}
            />
          </Grid>
        </Grid>
        <div
          className={ClassNames(['card-section'])}
          style={{
            position: 'relative',
          }}>
          {!projects || (projects.length === 0 && loading) ? (
            <Skeleton animation="wave" variant="rect" width={1} height={1} />
          ) : (
            <MobHorizontalScrollNew data={firstmenu} />
          )}
        </div>
      </div>
    </section>)
  );
};

const MobNewProject = handleViewport(NewProjectSection, {
  rootMargin: '-1.0px',
});

const mapStateToProps = state => ({
  apiData: state.investmentReducer,
});

const mapDispatchToProps = {
  getDiscountDealProjects,
  nextDiscountDealProjects,
  addWishlist,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobNewProject);
