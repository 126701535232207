import React, { useRef, useState, useEffect } from 'react';
import handleViewport from 'react-in-viewport';
import {
  getDiscountDealProjects,
  nextDiscountDealProjects,
  addWishlist,
} from '../../../redux/actions/main';
import FallbackCard from '../Common/FallbackCard';
import { connect } from 'react-redux';
import ViewAll from '../Common/ViewAll';
import {
  Grid2 as Grid,
  // Tooltip
} from '@mui/material';
import CommonHeaderText from '../../packages/common-header-text';
import HorizontalScrollButtons from '../Common/HorizontalScrollButtons';
import HorizontalScroll from '../../packages/horizontal-scroll';
import {
  capitalizeFirstLetter,
  slugify,
  gotoPropertyDetailsPage,
  getSearchPageURL,
  searchURLType,
} from '../../util';
import _ from 'lodash';
import useAccessToken from '../../packages/use-access-token';
import useUserInfo from '../../packages/use-user-info';
import MoreDetailTooltip from '@/components/PageComponents/PropertyPage/components/Tooltip';
import PropertyCard from '../../packages/property-card';
import Variables from 'styles/variables.module.scss';
import classes from './newproject.module.scss';

// All items component
// Important! add unique key
export const Menu = (
  list,
  loading,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  toggleWishlist,
  openLoginModal,
  module_type,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger
) =>
  list &&
  list.length > 0 &&
  list.map((el, index) => {
    return (
      <MenuItem
        text={el}
        key={index}
        cardIndex={index}
        loading={loading}
        open_modal={open_modal}
        propertyDetailsClick={propertyDetailsClick}
        propertyDetailsGallery={propertyDetailsGallery}
        toggleWishlist={toggleWishlist}
        openLoginModal={openLoginModal}
        module_type={module_type}
        cachedProject={cachedProject}
        wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
        addwishlisttrigger={addwishlisttrigger}
        setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
        deletecachedpropertytrigger={deletecachedpropertytrigger}
      />
    );
  });

const MenuItem = ({
  text,
  open_modal,
  cardIndex,
  propertyDetailsClick,
  propertyDetailsGallery,
  toggleWishlist,
  openLoginModal,
  module_type,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger,
}) => {
  const {
    pId,
    projectImage,
    projectName,
    weRecommendCount,
    developerName,
    maxPrice,
    minPrice,
    liveabilityIndexCount,
    localityName,
    cityName,
    bestForInvestmentCount,
    streetView,
    videoLink,
    minCarpetArea,
    maxCarpetArea,
    configurationTypeCount,
    wishlists,
    dealOffer,
    offer,
    furnishingStatus,
    propetyBuyOrRent,
  } = text;

  const bhk_type_label = Object.keys(configurationTypeCount);
  const bhk_types = bhk_type_label
    .map(data => {
      return configurationTypeCount[data].toString() + ' ' + data;
    })
    .toString();
  return (
    (<PropertyCard
      cardIndex={cardIndex}
      isResale={propetyBuyOrRent === 'resale'}
      maxCarpetArea={maxCarpetArea}
      setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
      deletecachedpropertytrigger={deletecachedpropertytrigger}
      addwishlisttrigger={addwishlisttrigger}
      wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
      cachedProject={cachedProject}
      property_id={pId}
      projectName={projectName}
      property_name={projectName.replace(' ', '-').toLowerCase()}
      propertyDetailsClick={propertyDetailsClick}
      propertyDetailsGallery={propertyDetailsGallery}
      scheduleTourClick={open_modal}
      livability_index={liveabilityIndexCount}
      investment_index={bestForInvestmentCount}
      location={localityName + ', ' + cityName}
      locationwithoutCityName={localityName}
      sqft={minCarpetArea + ` to ` + maxCarpetArea + ` SqFt`}
      rootStyle={{
        fontFamily: 'Open Sans',
        marginRight: '0px',
      }}
      images={projectImage ? projectImage : []}
      p_project_name={
        <div
          className={classes.newprojectname}
          style={
            {
              // color: '#EE6A29',
              // fontSize: '18px',
              // fontWeight: 700,
              // fontFamily: 'Open Sans',
            }
          }>
          {projectName.length > 31 ? (
            <MoreDetailTooltip
              title={projectName}
              PopperProps={{
                popperOptions: {
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: '-80px, -15px',
                    },
                  },
                },
              }}
              arrow>
              <CommonHeaderText text={projectName} variant="h4" />
            </MoreDetailTooltip>
          ) : (
            <CommonHeaderText text={projectName} variant="h4" />
          )}
        </div>
      }
      developer_name={
        <CommonHeaderText
          text={`By ` + developerName}
          variant="h5"
          style={{
            paddingBottom: '6px',
          }}
        />
      }
      p_we_recommend_count={weRecommendCount}
      street_url={streetView}
      video_url={videoLink}
      price={
        minPrice && maxPrice && parseInt(minPrice) && parseInt(maxPrice) ? (
          <Grid container>
            <Grid size={1}>
              <img
                src="/static/images/home-icons.svg"
                alt="rupee"
                style={{
                  objectPosition: '-29px 13px',
                  objectFit: 'cover',
                  width: '14px',
                  height: '28px',
                  marginTop: '-9px',
                }}
              />
            </Grid>
            <Grid sx={{ marginLeft: '-3px' }}>
              <span className={classes.priceContent}>
                {maxPrice !== minPrice ? minPrice + ' - ' + maxPrice : minPrice}
              </span>
            </Grid>
          </Grid>
        ) : (
          <div
            className="cardNotClicked"
            // onClick={() => open_modal(pId, projectName)}>
            onClick={event => {
              event.preventDefault(); // Prevent the default click behavior
              open_modal(pId, projectName, false, true);
            }}>
            <span
              className="cardNotClicked"
              style={{
                color: Variables.darkColor,
                fontSize: '18px',
                fontWeight: 700,
                fontFamily: 'Open Sans',
                marginBottom: '3px',
              }}>
              Price On Request
            </span>
          </div>
        )
      }
      bhk_types={bhk_types}
      wishlists={wishlists}
      toggleWishlist={toggleWishlist}
      openLoginModal={openLoginModal}
      module_type={slugify(module_type)}
      cityName={slugify(cityName)}
      offer={offer}
      dealOffer={dealOffer}
      priceToshow={
        maxPrice !== minPrice ? minPrice + ' - ' + maxPrice : minPrice
      }
      maxPrice={maxPrice}
      minPrice={minPrice}
      minCarpetArea={minCarpetArea}
      furnishingStatus={furnishingStatus}
    />)
  );
};

// const useStyles = makeStyles({
// highlightWord: {
//   color: Variables.secondaryColor,
//   fontWeight: 'bold',
//   fontFamily: 'Open Sans, sans-serif !important',
// },
// capitals: {
//   textTransform: 'uppercase',
//   // color:"#171717"
// },
// developerCardGrid: {
//   height: '100%',
//   maxHeight: '450px',
// },
// headingText: {
// '& h2': {
//   fontFamily: 'Open Sans, sans-serif !important',
//   letterSpacing: '-0.01562em !important',
// },

// '& h6': {
//   fontFamily: 'Open Sans, sans-serif !important',
//   fontWeight: '400!important',
//   textTransform: 'uppercase',
// },
// },
// });

const NewProjectSection = props => {
  let { module_type } = props;
  const {
    // inViewport,
    forwardedRef,
    open_modal,
    propertyDetailsGallery,
    openLoginModal,
    city,
    cachedProject,
    callWishlistApi,
    wishlistPropertyDeletedCall,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    data,
  } = props;
  const [access_token] = useAccessToken();
  const [user] = useUserInfo();
  module_type = module_type || 'buy';

  const { projects, loading } = data ?? props.apiData;
  const componentRef = useRef();
  const [weekday, setweekday] = useState();
  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  const propertyDetailsClick = (
    property_id,
    property_name,
    locationwithoutCityName
  ) => {
    gotoPropertyDetailsPage({
      module_type: module_type,
      city_name: city?.name,
      property_name: property_name,
      property_id: property_id,
      localityName: locationwithoutCityName,
    });
  };

  const toggleWishlist = propertyid => {
    props.addWishlist({
      data: {
        property_id: propertyid,
        user_id: user?.id,
        module_type: module_type,
      },
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
      callBack: callWishlistApi,
    });
    wishlistPropertyDeletedCall();
  };
  const firstmenu = Menu(
    projects,
    loading,
    open_modal,
    propertyDetailsClick,
    propertyDetailsGallery,
    toggleWishlist,
    openLoginModal,
    module_type,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger
  );

  // const classes = useStyles();

  const highlightedWord = word => {
    return <span className={classes.highlightWord}>{word}</span>;
  };

  const [limit] = useState(3);
  const [windowSize] = useState(3);
  const investmentProjects = _.debounce(
    (get, moduletype, global_selected_city_id, userId, skips) => {
      const queryParams = {
        city_id: global_selected_city_id,
        skip: skips,
        limit: limit,
        module_type: moduletype,
        section: 'investment',
        // access_token: access_token,
        user_id: userId,
      };
      if (get) {
        props?.getDiscountDealProjects(queryParams);
      } else {
        props?.nextDiscountDealProjects(queryParams);
      }
    }
  );
  //get first day of week
  function getWeekBegin() {
    let now = new Date();
    let next = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + (7 - now.getDay())
    );
    return next;
  }

  useEffect(() => {
    //convert date into required format
    function convert(str) {
      let date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2);
      return [day, months[mnth - 1], date.getFullYear()].join(' ');
    }

    let firstDay = getWeekBegin();
    let endsForDayNew = convert(firstDay);

    setweekday(endsForDayNew);
  }, [weekday]);

  const [places, setplaces] = useState(0);
  const [firstMount, setfirstMount] = useState(false)
  useEffect(() => {
    if (!firstMount) {
      setfirstMount(true)
      return;
    }
    let mounted = false;
    const userId = user?.id;
    if (!mounted) {
      setplaces(0);
      investmentProjects(true, module_type, city?.id, userId);
    }

    return () => {
      mounted = true;
    };
  }, [city, module_type]);

  const loadPrevProjects = () => {
    setplaces(prev => prev - 1);
    componentRef?.current?.handleArrowClick();
  };

  const loadNextProjects = () => {
    setplaces(prev => prev + 1);
    componentRef?.current?.handleArrowClickRight();
  };

  return projects.length !== 0 ? (
    <section
      ref={forwardedRef}
      className="main-section"
      style={{ marginTop: '40px' }}>
      <div className="container">
        <Grid container style={{ paddingBottom: '20px' }}>
          <Grid
            className={classes.headingText}
            justifyContent="flex-start"
            alignItems="center"
            container
            size={9}>
            <CommonHeaderText
              text={
                <>
                  {highlightedWord('Discounted Deals ')}in{' '}
                  {capitalizeFirstLetter(city?.name)}
                </>
              }
              variant="h2"
            />
            <CommonHeaderText text={<> Ends on {weekday}</>} />
          </Grid>
          {projects?.length > 3 ? (
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginLeft: '-8px',
              }}
              size={3}>
              <div
                style={{
                  paddingRight: '15px',
                  borderRight: '2px solid #17171721',
                }}>
                <ViewAll
                  text="View All"
                  url={getSearchPageURL(
                    searchURLType.discountedDeals,
                    module_type,
                    city?.name
                  )}
                />
              </div>
              <div
                style={{
                  paddingLeft: '15px',
                }}>
                <HorizontalScrollButtons
                  loadPrevProjects={loadPrevProjects}
                  loadNextProjects={loadNextProjects}
                  disableNext={projects.length < (places + 1) * windowSize}
                  disablePrev={places === 0}
                />
              </div>
            </Grid>
          ) : null}
        </Grid>
        <Grid container className="card-section" style={{marginTop:'-20px'}}>
          <Grid size={12}>
            {!projects || projects.length === 0 || loading ? (
              <Grid
                spacing={2}
                container
                justifyContent="center"
                alignitems="center">
                <Grid size={4}>
                  <FallbackCard />
                </Grid>
                <Grid size={4}>
                  <FallbackCard />
                </Grid>
                <Grid size={4}>
                  <FallbackCard />
                </Grid>
              </Grid>
            ) : (
              <HorizontalScroll
                itemClass="property-card-item "
                componentRef={componentRef}
                data={firstmenu}
                dragging={false}
              />
            )}
          </Grid>
        </Grid>
        {/* )} */}
      </div>
    </section>
  ) : (
    ''
  );
};

const NewProject = handleViewport(NewProjectSection, { rootMargin: '-1.0px' });

const mapStateToProps = state => ({
  apiData: state.investmentReducer,
  headersApiData: state.headerReducer,
});

const mapDispatchToProps = {
  getDiscountDealProjects,
  nextDiscountDealProjects,
  addWishlist,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProject);
